import React, { useEffect, useState } from 'react'
import './style.css'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useInView } from 'react-intersection-observer';

export default function Why() {
    const [faq, setFaq] = useState("")
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <section className={inView? 'Why Why_Animate' : 'Why'}>
            <span>
                <div className='Why_Line'>
                    <div className={inView? 'Line Why_Line_Fill' : 'Line'} />
                </div>
                <h3>WHY INOVADOR</h3>
                <h2>Accurate Alerts with Integrated Security Analytics.</h2>
                <div ref={ref} className='Why_Con'>
                    <div className='Solution_FAQ_Con'>
                                    {faqs?.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div style={{ borderColor: index === 0 ? "#000" : "#fff", color:"#fff" }} className='Solution_FAQ' onClick={() => faq === index ? setFaq(null) : setFaq(index)}>
                                                    {item?.question}
                                                    {faq === index? <MdKeyboardArrowUp  size={18} color='#fff' /> :<MdKeyboardArrowDown size={18} color='#fff' />}
                                                </div>
                                                <div style={{color:"#fff"}} className={faq === index ? 'Solution_FAQ_Ans Solution_FAQ_Ans_Show' : 'Solution_FAQ_Ans'}>
                                                    {item?.answer}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                </div>
            </span>
            <div className='FlexCon' />
            <div className='Why_Img_Con'>
                <img src={require('../assets/why.jpg')}/>
                <div className='Why_Img_Overlay'/>
            </div>
        </section>
    )
}


const faqs = [
    { question: "Collect pertinent information", answer: "Collecting pertinent information is crucial for threat analysis and prevention, aiding in identifying vulnerabilities, monitoring system activity, and ensuring robust defense mechanisms." },
    { question: "Identify actual threats", answer: "Identifying actual threats involves discerning real-time risks to systems and data, enabling prompt response to mitigate potential harm and safeguard against malicious activities." },
    { question: "Perform with accuracy", answer: "Performing with accuracy is essential for precise threat detection, incident response, and system protection, ensuring effective defense against evolving cyber threats with precision and reliability." },
]