import React, { useState } from 'react'

export default function NewsLetter() {
  const [email, setEmail] = useState("")
  
  const onSubmit = (e) =>{
    e.preventDefault()
  }

  return (
    <div className='Newsletter'>
        <span>
            <h2>Be the First to Receive the Latest News From Inovador.</h2>
            <label>Email*</label>
            <form onSubmit={(e)=>onSubmit(e)}>
                <input value={email} onChange={(e)=>setEmail(e.target.value)}/>
                <button>SUBSCRIBE</button>
            </form>
        </span>
    </div>
  )
}
