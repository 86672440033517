import React from 'react'
import { GiMoebiusTriangle } from 'react-icons/gi'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='Footer'>
        <span>
            <div className='Footer_Logo_Con'>
                <h2>Inovador Techsolutions</h2>
                <GiMoebiusTriangle color='#000' className='Header_Icon'/>
            </div>
            <div className='Footer_Con1'>
            <div className='Footer_Con'>
                <h2>COMPANY</h2>
                <Link to={'/about'}>About us</Link>
            </div>
            <div className='Footer_Con'>
                <h2>LEGAL</h2>
                <Link to={'/terms'}>Terms & Conditions</Link>
            </div>
            <div className='Footer_Con'>
                <h2>SOCIAL</h2>
                <a href='https://www.linkedin.com/posts/kelly-mizell-%F0%9F%8C%9F-67084331_talent-unique-believeinyou-activity-7158399299641339904-JZSG?utm_source=share&utm_medium=member_android' target='_blank'>LinkedIn</a>
                <a href='https://www.facebook.com/profile.php?id=61556298684480&mibextid=ZbWKwL' target='_blank'>Facebook</a>
            </div>
            <div className='Footer_Con'>
                <h2>CONTACT</h2>
                <a style={{wordBreak:"break-all"}} href='mailto:suhas.nandan@inovadortechsolutions.com'>suhas.nandan@inovadortechsolutions.com</a>
                <a href='tel:123-456-7890'>Tel: +91 9740760571</a>
                <a>No. 81/B(Old No. 174/20),<br/>8th Main, 3rd Floor,<br/>Jayanagar 3rd Block,<br/>Bengaluru-560011</a>
            </div>
            </div>
        </span>
        <p>© 2024 by Inovador Techsolutions</p>
    </div>
  )
}
