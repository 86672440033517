import React from 'react'
import { MdOutlineArrowForward } from 'react-icons/md'
import './style.css'

export default function AboutMission() {
    return (
        <section style={{ backgroundColor: "#fff" }} className='Solution'>
            <span>
                <div style={{ backgroundColor: "#c2c2c2" }} className='Why_Line'>
                    <div style={{ backgroundColor: "#000" }} className='Why_Line_Fill' />
                </div>
                <h3 style={{ color: "#000" }}>ABOUT US</h3>
                <h2 style={{ color: "#000" }}>Protecting Your Digital World with Confidence</h2>
                <p>
                    At our cyber security website, we are dedicated to providing you with the highest level of protection against cyber threats. With our team of experts and cutting-edge technology, we strive to ensure the safety and security of your digital assets.
                    <br /><br /><b>Our Expertise in Cyber Security</b>
                    <br /><br />With years of experience in the field, our team possesses deep knowledge and expertise in all aspects of cyber security. From network security to data encryption, we are well-equipped to handle any challenge that comes our way.
                    <br /><br /><b>State-of-the-Art Technology</b>
                    <br /><br />We leverage the latest advancements in technology and employ industry-leading tools to safeguard your systems and data. Our comprehensive solutions are designed to identify vulnerabilities, detect intrusions, and prevent attacks.
                    <br /><br /><b>Your Trusted Partner</b>
                    <br /><br />We understand the importance of trust when it comes to cyber security. That's why we prioritize transparency, reliability, and confidentiality in all our interactions. Rest assured that your information is safe in our hands.
                    <br /><br /><b>Customized Solutions for Your Needs</b>
                    <br /><br />We recognize that every organization has unique requirements when it comes to cyber security. That's why we offer tailored solutions that align with your specific needs and goals. Our team will work closely with you to develop a strategy that best suits your business.
                    <br /><br /><b>Contact Us Today</b>
                    <br /><br />If you are ready to take proactive steps towards protecting your digital world, contact us today. Our friendly team is here to answer any questions you may have and guide you through the process of securing your digital assets.
                </p>
            </span>
            <div className='Why_Space' />
        </section>
    )
}
