import React from 'react'
import './style.css'
import { useInView } from 'react-intersection-observer';

export default function Collaboration() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <section className={inView? 'Collaboration Collaboration_Animate' : 'Collaboration'}>
            <span>
                <div className='Collaboration_Line'>
                    <div className={inView? 'Line Collaboration_Line_Fill' : 'Line'} />
                </div>
                <h3>FEATURES</h3>
                <h2>Simplify Threat Detection Without Sacrifice or Blind Spots</h2>
                <div ref={ref} className='Solution_Grid_Con'>
                    <div className='Solution_Grid_Con1'>
                        <h5>Observe Anything and Everything</h5>
                    </div>
                    <div className='Solution_Grid_Con1'>
                        <h5>Discern and Disarm Real Threats</h5>
                    </div>
                    <div className='Solution_Grid_Con1'>
                        <h5>Get Flexibility and Choice</h5>
                    </div>
                    <div className='Solution_Grid_Con1'>
                        <h5>Predict Costs, Unleash SecOps</h5>
                    </div>
                </div>
            </span>
        </section>
    )
}
