import React, { useEffect, useState } from 'react'
import { GiMoebiusTriangle } from 'react-icons/gi'
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './style.css'

export default function Header({divRef}) {
    const location = useLocation()
    const navigate = useNavigate()
    const [scrollPosition, setScrollPosition] = useState(window.scrollY)
    const [show, setShow] = useState(false)
    const [solution, setSolution] = useState(false)
    const [service, setService] = useState(false)
    
    const handleScroll = () => {
        const newPosition = divRef?.current.scrollTop;
        setScrollPosition(newPosition);
    };

    useEffect(() => {
        divRef?.current.addEventListener('scroll', handleScroll);

        return () => {
            divRef?.current.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return (
        <>
        <div className={scrollPosition>window.innerHeight*1? 'Header Header_Hide' : scrollPosition>5 && location.pathname === '/'? 'Header Header_Black' : scrollPosition>5? 'Header Header_White' : 'Header'}>
            <span>
                <Link to={'/'} className='Header_Logo' style={{color:location.pathname === '/'? null : "#000"}}>Inovador Techsolutions</Link>
                <GiMoebiusTriangle color={location.pathname === '/'?'#fff':"#000"} className='Header_Icon'/>
                <div className='FlexCon' />
                <ul>
                    <li onMouseEnter={()=>setSolution(true)} onMouseLeave={()=>setSolution(false)}>
                        <a style={{color:location.pathname === '/'? null : "#000"}}>Solutions</a>
                        <div className={solution? 'Header_Modal1 Header_Modal_Show' : 'Header_Modal1'}>
                            {solutions?.map((itm, ind)=>{
                                return(
                                    <p key={ind} onClick={()=>{
                                        navigate(`/solution/${itm?.path}`)
                                        setSolution(false)
                                    }}>{itm?.title}</p>
                                )
                            })}
                        </div>
                    </li>
                    <li onMouseEnter={()=>setService(true)} onMouseLeave={()=>setService(false)}>
                        <a style={{color:location.pathname === '/'? null : "#000"}}>Services</a>
                        <div className={service? 'Header_Modal Header_Modal_Show' : 'Header_Modal'}>
                            {services?.map((itm, ind)=>{
                                return(
                                    <p key={ind} onClick={()=>{
                                        navigate(`/service/${itm?.path}`)
                                        setService(false)
                                    }}>{itm?.title}</p>
                                )
                            })}
                        </div>
                    </li>
                    <li style={{color:location.pathname === '/'? null : "#000"}} onClick={()=>navigate('/about')}>About Us</li>
                </ul>
                {location.pathname === '/'?
                <button onClick={()=>navigate('/contact')} className='Header_Btn'>CONTACT</button>
                :
                <button onClick={()=>navigate('/contact')} className='Header_Btn1'>CONTACT</button>
                }
                <AiOutlineMenu color={location.pathname === '/'? '#fff' : '#000'} className='Header_Menu_Icon' onClick={()=>setShow(true)}/>
            </span>
        </div>
        <div className={show? 'Menu_Modal Menu_Modal_Show':'Menu_Modal'}>
            <AiOutlineClose color='#000' className='Header_Menu_Icon1' onClick={()=>setShow(false)}/>
            <div className='Menu_Modal_Con'>
                <Link>Solutions</Link>
            </div>
            {solutions?.map((itm, ind)=>{
                return(
                    <p key={ind} style={{margin:"0 10vw", marginBottom:15}} onClick={()=>{
                        navigate(`/solution/${itm?.path}`)
                        setShow(false)
                    }}>{itm?.title}</p>
                )
            })}
            <div className='Menu_Modal_Con'>
                <Link>Services</Link>
            </div>
            {services?.map((itm, ind)=>{
                return(
                    <p key={ind} style={{margin:"0 10vw", marginBottom:15}} onClick={()=>{
                        navigate(`/solution/${itm?.path}`)
                        setShow(false)
                    }}>{itm?.title}</p>
                )
            })}
            <div className='Menu_Modal_Con'>
                <Link onClick={()=>setShow(false)} to={'/about'}>About</Link>
            </div>
        </div>
        </>
    )
}

const solutions = [
    {title: "Insider Threat", path:"Insider-Threat"},
    {title: "SOC Transformation", path:"SOC-Transformation"},
    {title: "Cloud Security Monitoring", path:"Cloud-Security-Monitoring"},
    {title: "Fraud Detection and Prevention", path:"Fraud-Detection-and-Prevention"},
    {title: "Privileged Access Monitoring", path:"Privileged-Access-Monitoring"},
    {title: "Compliance", path:"Compliance"}
]

const services = [
    {title: "Payment Gateway", path:"Payment-Gateway"},
    {title: "Web Development", path:"Web-Development"},
    {title: "Mobile App Development", path:"Mobile-App-Development"},
    {title: "AI Development", path:"AI-Development"},
    {title: "Cyber Security Services", path:"Cyber-Security-Services"},
]