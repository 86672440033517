import React from 'react'
import { MdOutlineArrowForward } from 'react-icons/md'
import './style.css'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer';

export default function Products() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <section className={inView? 'Products Products_Animate' : 'Products'}>
            <span>
                <div className='Products_Line'>
                    <div className={inView? 'Line Products_Line_Fill':'Line'} />
                </div>
                <h3>OUR SERVICEs</h3>
                <h2>Take a Look at the Featured Services and Use Cases</h2>
            </span>
            <div ref={ref} className='Products_Slide'>
                <div className='Products_Slide_Left'>
                    <div className='Products_Slide_Line'>
                        <div className='Products_Slide_Line_Fill' />
                    </div>
                    <div className='Products_Slide_Left_Con'>
                        <h3>Payment Gateway</h3>
                        <h2>50+ <b>Payment Modes</b></h2>
                        <div className='Products_Slide_Left_Sep' />
                        <h2>24/7 <b>Support</b></h2>
                        <p>Experience seamless and secure transactions with our advanced payment gateway. We prioritize convenience for both businesses and customers, ensuring swift and reliable payment processing. </p>
                        <Link to='/service/Payment-Gateway'>
                            <button>
                                READ MORE
                                <MdOutlineArrowForward className='Products_Slide_Btn_Icon' />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='Products_Slide_Right'>
                <img src={require('../assets/money.jpg')} />
                </div>
            </div>
            <div className='Products_Slide1'>
                <div className='Products_Slide_Right'>
                    <img src={require('../assets/software.avif')} />
                </div>
                <div className='Products_Slide_Left'>
                    <div className='Products_Slide_Left_Con'>
                        <h3>Web Development</h3>
                        <h2>20+ <b>Frontend Developers</b></h2>
                        <div className='Products_Slide_Left_Sep' />
                        <h2>15+ <b>Backend Developers</b></h2>
                        <p>Innovative web development company delivering cutting-edge solutions. Our team of skilled professionals transforms ideas into interactive, user-friendly websites and applications. With a focus on creativity and functionality, we strive to exceed client expectations.</p>
                        <Link to='/service/Web-Development'>
                            <button>
                                READ MORE
                                <MdOutlineArrowForward className='Products_Slide_Btn_Icon' />
                            </button>
                        </Link>
                    </div>
                    <div className='Products_Slide_Line'>
                        <div className='Products_Slide_Line_Fill' />
                    </div>
                </div>
            </div>
            <div className='Products_Slide2'>
                <div className='Products_Slide_Left'>
                    <div className='Products_Slide_Line'>
                        <div className='Products_Slide_Line_Fill' />
                    </div>
                    <div className='Products_Slide_Left_Con'>
                        <h3>Web Development</h3>
                        <h2>20+ <b>Frontend Developers</b></h2>
                        <div className='Products_Slide_Left_Sep' />
                        <h2>15+ <b>Backend Developers</b></h2>
                        <p>Innovative web development company delivering cutting-edge solutions. Our team of skilled professionals transforms ideas into interactive, user-friendly websites and applications. With a focus on creativity and functionality, we strive to exceed client expectations.</p>
                        <Link to='/service/Web-Development'>
                            <button>
                                READ MORE
                                <MdOutlineArrowForward className='Products_Slide_Btn_Icon' />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='Products_Slide_Right'>
                    <img src={require('../assets/software.avif')} />
                </div>
            </div>
            <div className='Products_Slide'>
                <div className='Products_Slide_Left'>
                    <div className='Products_Slide_Line'>
                        <div className='Products_Slide_Line_Fill' />
                    </div>
                    <div className='Products_Slide_Left_Con'>
                        <h3>Mobile App Development</h3>
                        <h2>20+ <b>Frontend Developers</b></h2>
                        <div className='Products_Slide_Left_Sep' />
                        <h2>15+ <b>Backend Developers</b></h2>
                        <p>Our company specialises in developing unique mobile apps for a range of platforms and industries. Our team of talented and experienced designers, developers, and testers is capable of producing excellent and user-friendly apps.</p>
                        <Link to='/service/Mobile-App-Development'>
                            <button>
                                READ MORE
                                <MdOutlineArrowForward className='Products_Slide_Btn_Icon' />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='Products_Slide_Right'>
                    <img src={require('../assets/mobile.jpg')} />
                </div>
            </div>
        </section>
    )
}
