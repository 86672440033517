import React, { useEffect, useRef } from 'react'
import './style.css'
import { useNavigate, useParams } from 'react-router-dom'

export default function Product() {
    const params = useParams()

    const productRef = useRef(null)
    
    useEffect(()=>{
        productRef?.current?.scrollIntoView({behavior: "smooth"})
    },[productRef])

    const data = datas?.find((e)=>e.id === params?.id)
    
    return (
        <section ref={productRef} style={{backgroundColor:"#fff"}} className='Why'>
            <span>
                <div style={{backgroundColor:"#c2c2c2"}} className='Why_Line'>
                    <div style={{backgroundColor:"#000"}} className='Why_Line_Fill' />
                </div>
                <div>
                    <h3 style={{color:"#000"}}>{data?.sub}</h3>
                </div>
                <h2 style={{color:"#000"}}>{data?.title}</h2>
                <div className='Why_Con1'>
                    <img src={data?.img}/>
                    <p dangerouslySetInnerHTML={{__html:data?.discr}}/>
                </div>
            </span>
            <div className='Why_Space'/>
        </section>
    )
}

const datas = [
    {id:"Inovador-SIEM", sub:"INOVADOR SIEM", title:"Find the unknown threads", discr:'Heritage "compile and link" SIEMs are ill-suited to the dynamic, complicated, and data-intensive situations of today. Are you inundated with pointless notifications and excessive data consumption charges? Inovador can assist if your SIEM is failing you.<br/><br/>With the integrated powers of SIEM, UEBA, SOAR, and Identity & Access Analytics from the most extensive and adaptable Next-Gen SIEM in the market, transform and empower your SOC.', img:'https://images.unsplash.com/photo-1590065707046-4fde65275b2e?q=80&w=2830&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
    {id:"Inovador-UEBA", sub:"INOVADOR UEBA", title:"Advance Threats Detection", discr:"Threats are a dynamic object. Since most SIEM and XDR systems are unable to piece together events across time, determined and persistent threat actors intentionally spread out their activities over weeks or even months.<br/><br/>What's worse is that these solutions mostly rely on pattern matching, or rule-based machine learning. Because of this, they are particularly useless at identifying fresh attacks and/or their variations, which are very effective at breaking into businesses. Find out how your business may benefit from Inovador UEBA security.", img:"https://images.unsplash.com/photo-1598946114934-8cf259aa241d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
    {id:"Inovador-IAA", sub:"INOVADOR IAA", title:"Identity & Access Analytics", discr:"The implementation of zero trust programmes, privileged access regulations, and identity security are essential measures in safeguarding organisations against malevolent actors. Identity is becoming the new perimeter. Due to the increase in credential theft, contemporary insider threats, and misuse of privileged access, security teams must baseline access regulations, keep an eye out for internal and external identity risks and threats, and collaborate across functional boundaries to achieve continuous improvement.<br/><br/>The ignorance of unaccounted for access privileges is a serious problem for security teams. This reveals significant identification and security vulnerabilities that threat actors can readily take advantage of to infiltrate networks, travel across borders, and steal data. As part of threat detection, investigation, and response, identity baselining, comprehending access privileges and entitlements, and subsequent monitoring are essential (TDIR). To enhance their access policies and entitlements, Identity Access Management (IAM) and Privileged Access Management (PAM) can also benefit from Identity Analytics and Access Analytics.", img:"https://images.unsplash.com/photo-1434626881859-194d67b2b86f?q=80&w=2948&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
]