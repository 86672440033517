import React, { useEffect, useRef } from 'react'
import './style.css'

export default function Terms() {
  const termRef = useRef(null)
  
  useEffect(()=>{
    termRef?.current?.scrollIntoView({behavior: "smooth"})
  },[termRef])

  return (
    <div ref={termRef} className='Terms'>
        <span>
            <h2>Terms & Conditions</h2>
            <h3>A LEGAL DISCLAIMER</h3>
            <p>The explanations and information provided on this page are only general and high-level explanations and information on how to write your own document of Terms & Conditions. You should not rely on this article as legal advice or as recommendations regarding what you should actually do, because we cannot know in advance what are the specific terms you wish to establish between your business and your customers and visitors. We recommend that you seek legal advice to help you understand and to assist you in the creation of your own Terms & Conditions. </p>
            <h3>TERMS & CONDITIONS - THE BASICS</h3>
            <p>Having said that, Terms and Conditions (“T&C”) are a set of legally binding terms defined by you, as the owner of this website. The T&C set forth the legal boundaries governing the activities of the website visitors, or your customers, while they visit or engage with this website. The T&C are meant to establish the legal relationship between the site visitors and you as the website owner.
                <br/><br/>T&C should be defined according to the specific needs and nature of each website. For example, a website offering products to customers in e-commerce transactions requires T&C that are different from the T&C of a website only providing information (like a blog, a landing page, and so on).     
                <br/><br/>T&C provide you as the website owner the ability to protect yourself from potential legal exposure, but this may differ from jurisdiction to jurisdiction, so make sure to receive local legal advice if you are trying to protect yourself from legal exposure.
            </p>
            <h3>WHAT TO INCLUDE IN THE T&C DOCUMENT</h3>
            <p>Generally speaking, T&C often address these types of issues: Who is allowed to use the website; the possible payment methods; a declaration that the website owner may change his or her offering in the future; the types of warranties the website owner gives his or her customers; a reference to issues of intellectual property or copyrights, where relevant; the website owner’s right to suspend or cancel a member’s account; and much much more. 
                <br/><br/>To learn more about this, check out our article “Creating a Terms and Conditions Policy”.</p>
        </span>
    </div>
  )
}
