import React, { useEffect, useRef } from 'react'
import './style.css'

export default function Hero() {
  const heroRef = useRef()
  
  useEffect(()=>{
    heroRef?.current?.scrollIntoView({behavior: "smooth"})
  },[heroRef])

  return (
    <section ref={heroRef} className='Hero'>
        <span>
          <div className='Hero_Text_Con'>
            <h2>Human Security Team Empowered By AI</h2>
            <p>Securing Progress, Shielding Innovation:<br/>Global Cybersecurity Excellence.</p>
          </div>
        </span>
    </section>
  )
}