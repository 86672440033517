import React, { useEffect, useRef, useState } from 'react'
import './style.css'

export default function Contact() {
  const aboutRef = useRef(null)
  
  useEffect(()=>{
    aboutRef?.current?.scrollIntoView({behavior: "smooth"})
  },[aboutRef])

  const [submit, setSubmit] = useState(false)

  const [first, setFirst] = useState("")
  const [last, setLast] = useState("")
  const [mail, setMail] = useState("")
  const [org, setOrg] = useState("")
  const [msg, setMsg] = useState("")

  return (
    <div ref={aboutRef} className='Collaboration'>
        <span>
            <h4>Get in Touch</h4>
            <p>REACH OUT, SHARE A QUESTION OR<br/>PROVIDE FEEDBACK ON OUR PRODUCTS.</p>
            <form onSubmit={(e)=>{
                e.preventDefault()
                setTimeout(() => {
                    setSubmit(true)
                    setFirst("")
                    setLast("")
                    setMail("")
                    setOrg("")
                    setMsg("")
                }, 500);
            }}>
                <div className='Collaboration_Form'>
                    <div className='Collaboration_Form_Con'>
                        <label>First Name *</label>
                        <input value={first} onChange={(e)=>setFirst(e.target.value)} className='Collaboration_inp1'/>
                    </div>
                    <div className='Collaboration_Form_Con Collaboration_Form_Con1'>
                        <label>Last Name *</label>
                        <input value={last} onChange={(e)=>setLast(e.target.value)} className='Collaboration_inp1'/>
                    </div>
                </div>
                <div className='Collaboration_Form'>
                    <div className='Collaboration_Form_Con'>
                        <label>Email *</label>
                        <input value={mail} onChange={(e)=>setMail(e.target.value)} className='Collaboration_inp2'/>
                    </div>
                </div>
                <div className='Collaboration_Form'>
                    <div className='Collaboration_Form_Con'>
                        <label>Organisation</label>
                        <input value={org} onChange={(e)=>setOrg(e.target.value)} className='Collaboration_inp2'/>
                    </div>
                </div>
                <div className='Collaboration_Form'>
                    <div className='Collaboration_Form_Con'>
                        <label>Message</label>
                        <textarea value={msg} onChange={(e)=>setMsg(e.target.value)} className='Collaboration_inp3'/>
                    </div>
                </div>
                <button>{submit? "SUBMITTED!" : "SUBMIT"}</button>
            </form>
        </span>
    </div>
  )
}
