import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import './App.css'

import Header from './layout/header';
import Hero from './pages/hero';
import Vision from './pages/vision';
import Product from './pages/product';
import Company from './pages/company';
import Why from './pages/why';
import Collaboration from './pages/collaboration';
import NewsLetter from './pages/newsletter';
import Footer from './pages/footer';
import AboutHero from './pages/aboutHero';
import AboutMission from './pages/aboutMission';
import Terms from './pages/terms';
import Products from './pages/products';
import Contact from './pages/contact';
import Solution from './pages/solution';

function App() {
  const appRef = useRef()
  return (
    <Router>
      <div ref={appRef} className='App'>
        <Header divRef={appRef}/>
        <Routes>
            <Route path={'*'} element={<Navigate to={'/'} />} />
            <Route path={'/'} element={<Landing/>} />
            <Route path={'/about'} element={<About/>} />
            <Route path={'/product/:id'} element={<ProductDetail/>} />
            <Route path={'/solution/:id'} element={<Solutions/>} />
            <Route path={'/service/:id'} element={<Solutions/>} />
            <Route path={'/terms'} element={<TermsCondition/>} />
            <Route path={'/contact'} element={<Contacts/>} />
        </Routes>
      </div>
    </Router>
  );
}

function Landing(){
  return(
    <>
    <Hero/>
    <Why/>
    <Products/>
    {/* <Vision/> */}
    {/* <Company/> */}
    <Collaboration/>
    <NewsLetter/>
    <Footer/>
    </>
  )
}

function About(){
  return(
    <>
    {/* <AboutHero/> */}
    <AboutMission/>
    <NewsLetter/>
    <Footer/>
    </>
  )
}

function TermsCondition(){
  return(
    <>
    <Terms/>
    <NewsLetter/>
    <Footer/>
    </>
  )
}

function ProductDetail(){
  return(
    <>
      <Product/>
      <NewsLetter/>
      <Footer/>
    </>
  )
}

function Contacts(){
  return(
    <>
      <Contact/>
      <NewsLetter/>
      <Footer/>
    </>
  )
}

function Solutions(){
  return(
    <>
      <Solution/>
      <NewsLetter/>
      <Footer/>
    </>
  )
}

export default App;
